<template lang="pug">
  .socials.columns.is-flex-mobile.is-marginless.is-gapless(:class="place")
    a.column(
      v-if="links.facebook"
      target="_blank"
      rel="noreferer noopener"
      :href="links.facebook")
      SvgIcon.icon(name="facebook-copy")
    a.column(
      v-if="links.instagram"
      target="_blank"
      rel="noreferer noopener"
      :href="links.instagram")
      SvgIcon.icon(name="instagram-copy")
    a.column(
      v-if="links.youtube"
      target="_blank"
      rel="noreferer noopener"
      :href="links.youtube")
      SvgIcon.icon(name="youtube")
    a.column(
      v-if="links.vk"
      target="_blank"
      rel="noreferer noopener"
      :href="links.vk")
      SvgIcon.icon(name="vk")
    a.column(
      v-if="links.tiktok"
      target="_blank"
      rel="noreferer noopener"
      :href="links.tiktok")
      SvgIcon.icon(name="tik_tok")
    a.column(
      v-if="links.twitter"
      target="_blank"
      rel="noreferer noopener"
      :href="links.twitter")
      SvgIcon.icon(name="twitter")
    a.column(
      v-if="links.medium"
      target="_blank"
      rel="noreferer noopener"
      :href="links.medium")
      SvgIcon.icon(name="medium")
    a.column(
      v-if="links.reddit"
      target="_blank"
      rel="noreferer noopener"
      :href="links.reddit")
      SvgIcon.icon(name="reddit")
    a.column(
      v-if="links.btc"
      target="_blank"
      rel="noreferer noopener"
      :href="links.btc")
      SvgIcon.icon(name="bitcointalk")
    a.column(
      v-if="links.telegram"
      target="_blank"
      rel="noreferer noopener"
      :href="links.telegram")
      SvgIcon.icon(name="telegram")
    a.column(
      v-if="links.youku"
      target="_blank"
      rel="noreferer noopener"
      :href="links.youku")
      SvgIcon.icon(name="youku")
</template>

<script>
export default {
  name: 'Socials',
  props: {
    place: {
      default: 'in-footer'
    }
  },
  computed: {
    links () {
      if (this.$translate.current === 'ru') {
        return {
          facebook: 'https://www.facebook.com/umi.top.official',
          instagram: 'https://www.instagram.com/umi.top.ru',
          vk: 'https://vk.com/umi_top',
          twitter: 'https://twitter.com/UMITOP',
          youtube: 'https://www.youtube.com/c/UMITop',
          btc: 'https://bitcointalk.org/index.php?topic=5252656',
          tiktok: 'https://www.tiktok.com/@umi_top',
          telegram: 'https://teleg.run/umi_news_ru'
        }
      }

      if (this.$translate.current === 'es') {
        return {
          facebook: 'https://www.facebook.com/umi.top.spain',
          instagram: 'https://www.instagram.com/umi.top.esp',
          telegram: 'https://t.me/umi_spain',
          twitter: 'https://twitter.com/SpainUmi',
          youtube: 'https://www.youtube.com/channel/UCOkiXSU7DNSDgfd_T6WwHJA'
        }
      }

      if (this.$translate.current === 'zh') {
        return {
          twitter: 'https://twitter.com/umi_top_eng',
          telegram: 'https://t.me/umi_china',
          youku: 'https://i.youku.com/i/UODA0NzU3NDEyMA'
        }
      }

      if (this.$translate.current === 'id') {
        return {
          telegram: 'https://t.me/umi_indonesia',
          instagram: 'https://www.instagram.com/umi.top.ind',
          youtube: 'https://www.youtube.com/channel/UCXSSCTGuzLvKLFyurbjivdQ'
        }
      }

      return {
        facebook: 'https://www.facebook.com/umi.top.eng',
        instagram: 'https://www.instagram.com/umi.eng',
        twitter: 'https://twitter.com/umi_top_eng',
        youtube: 'https://www.youtube.com/channel/UC8Beb0iYiIkb6YNx_gyQZpw',
        // medium: "https://medium.com/@umitop",
        btc: 'https://bitcointalk.org/index.php?topic=5252661',
        reddit: 'https://www.reddit.com/user/UMITop',
        telegram: 'https://t.me/umi_news_eng'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.socials {
  align-items: center;
}
.icon {
  &:hover {
    opacity: 0.85;
  }
}
.in-footer {
  min-width: 14rem;
  max-width: 30rem;
  .column {
    box-sizing: border-box;

    .extra-small {
      margin: 0.5rem;
    }

    &:hover {
      opacity: 0.85;
    }
    &:last-child {
      border-right: 0;
    }
  }
}
.in-button {
  .column {
    box-sizing: border-box;
    text-align: center;
    .icon {
      width: 1.25rem;
      height: 1.25rem;
    }
    .extra-small {
      margin: 0.5rem auto;
    }

    &:last-child {
      border-right: 0;
    }
  }
}
.in-banner {
  position: absolute;
  right: 1.5rem;
  // top: 0;
  // bottom: 0;
  margin: auto;
  flex-direction: column;
  .column {
    flex: none;
    width: unset;
    .icon {
      margin-bottom: 0.5rem;
    }
  }
  a:hover {
    opacity: 0.85;
  }
}
@media screen and (max-width: 425px) {
  .in-banner {
    right: 0.5rem;
    top: 9.625rem;
  }
}
</style>
